import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import SEO from '../components/SEO'
import IosTimeOutline from 'react-ionicons/lib/IosTimeOutline'



export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  type,
  date,
  timeToRead,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>

            <p className="is-size-6">{description}</p>

            <ul className="my-4 blog-info-border">
              <li className="info-item">
                <div className="tag is-white">
                  <span className="icon" ><IosTimeOutline /></span>
                  <span>&nbsp;{timeToRead} min read</span>
                </div>
              </li>
              <li className="info-item info-rest info-separator">
                  <div className={'tag is-light is-rounded mr-4 custom-tag-' + type.toLowerCase()}>
                    <span>{type}</span>
                  </div>

                  <div className="tag is-rounded">
                    <span>{date}</span>
                  </div>
              </li>
            </ul>

            <div className="content">
              <PostContent content={content} />
            </div>

            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <hr />

                <h1 className="is-size-6">Tags</h1>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link
                        className="button is-link is-light"
                        to={`/tags/${kebabCase(tag)}/`}
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <SEO 
            titleTemplate={"%s | Blog"}
            title={post.frontmatter.title}
            description={post.frontmatter.description}
            article={true}
            image={post.frontmatter.featuredimage.publicURL}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        type={post.frontmatter.type}
        date={post.frontmatter.date}
        timeToRead={post.timeToRead}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        type
        featuredimage {
          publicURL
        }
      }
    }
  }
`
